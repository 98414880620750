import { Header } from "../../components/Header"
import { PortfolioTitle, PortfolioSection, PortfolioList, PortfolioListItem, PortfolioParagraph, PortfolioA } from "../../utils/styles"
import { Footer } from "../../components/Footer";

export const EcologiquePage = () => 
{

    return (
        <div style={{height: "100%"}}>
            <Header blanc/>
            <section style={{paddingTop: "120px", margin: "0px 20px 0px 40px"}}>
                <PortfolioSection>
                    <PortfolioTitle>Description</PortfolioTitle>
                    <PortfolioParagraph>Eco-logique est une micro-entreprise créée par un autre BTS de mon lycée, celui-ci propose des services pour aider ses clients à être éco-responsable et à faire des économies sur leurs factures d'électricité à l'aide d'application mobile analytique, connecté à une prise connectée, permettant de connaître la consommation électrique d'un appareil sur une période. Je suis intervenu en tant développeur prestataire dans la création de l'application mobile.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Solutions apportées</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Application mobile</PortfolioListItem>
                        <PortfolioListItem>Serveur MQQT</PortfolioListItem>
                    </PortfolioList>                
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Difficultés</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Aucune expérience dans la création d'application mobile</PortfolioListItem>
                        <PortfolioListItem>Aucune expérience dans la création de serveur MQQT</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Compétences acquises</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Découverte et utilisation de React Native</PortfolioListItem>
                        <PortfolioListItem>Découverte et utilisation de l'API Shelly</PortfolioListItem>
                        <PortfolioListItem>Création et utilisation de serveur MQQT</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Etat du projet</PortfolioTitle>
                    <PortfolioParagraph>Plusieurs problèmes ont bloqué la communication entre l'équipe chargée du développement de la micro-entreprise et moi, ralentissant son développement. L'application mobile pourrait être fonctionnel avec une dizaine d'heures de travail supplémentaires.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Code source</PortfolioTitle>
                    <PortfolioParagraph>Disponible ici : <PortfolioA target="_blank" href="https://github.com/BrachV/eco-logique">Github</PortfolioA>
                    </PortfolioParagraph>
                </PortfolioSection>
            </section>
            <Footer />
        </div>
    )
}