import { Header } from "../../components/Header"
import { PortfolioTitle, PortfolioSection, PortfolioList, PortfolioListItem, PortfolioParagraph, PortfolioA } from "../../utils/styles"
import { Footer } from "../../components/Footer";

export const DrushPage = () => 
{

    return (
        <div style={{height: "100%"}}>
            <Header blanc/>
            <section style={{paddingTop: "120px", margin: "0px 20px 0px 40px"}}>
                <PortfolioSection>
                    <PortfolioTitle>Description</PortfolioTitle>
                    <PortfolioParagraph>DRush est un site web en développement avec pour objectif de simplifier le travail des streamers sur le site <PortfolioA target="_blank" href="https://twitch.tv/">twitch.tv</PortfolioA> </PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Difficultés</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Aucune expérience dans la création de site web avec une API interne</PortfolioListItem>
                        <PortfolioListItem>Projet très long et compliqué</PortfolioListItem>
                        <PortfolioListItem>Garder une interface intuitive et belle graphiquement</PortfolioListItem>
                        <PortfolioListItem>Sécurisation du système</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Compétences acquises</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Développement React avec une API interne</PortfolioListItem>
                        <PortfolioListItem>Développement d'une API avec Nest.js</PortfolioListItem>
                        <PortfolioListItem>Utilisation très avancée des web sockets</PortfolioListItem>
                        <PortfolioListItem>Sécurisation d'un serveur Linux</PortfolioListItem>
                        <PortfolioListItem>Installation sur un VPS de l'ensemble du système</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Etat du projet</PortfolioTitle>
                    <PortfolioParagraph>Le projet est toujours en développement, il devrait être disponible dans le courant de l'été.</PortfolioParagraph>
                </PortfolioSection>

            </section>
            <Footer />
        </div>
    )
}