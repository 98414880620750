import { useEffect } from "react";
import { Dot, HeaderContainer, Logo, Nav, NavItem  } from "../utils/styles";

type Props = {
    blanc?: boolean ;
};

export const Header = ({ blanc }: Props) => {

    useEffect(() => {
        if (blanc) return;
        window.addEventListener('scroll', function() {
            const header = document.querySelector('#header');
            header?.classList.toggle('sticky', window.scrollY > 0)
        })
    }, [])
    
    return (
        <header id="header" style={{position: "fixed", zIndex: 10, width: "100%"}} className={blanc ? "sticky": ""}>

            <HeaderContainer justifyContent="space-between" alignItems="center">
                <Logo className="logo">Cyanstone<Dot>.</Dot></Logo>
                <Nav alignItems="center" className="navigation" style={{margin: "30px"}}>
                    <NavItem className="nav-item" href="/">Accueil</NavItem>
                    <NavItem className="nav-item" href="/portfolio">Portfolio</NavItem>
                    <NavItem className="nav-item" href="/a-propos">A propos</NavItem>
                    <NavItem className="nav-item" href="/contact">Contact</NavItem>
                </Nav>
            </HeaderContainer>
        </header>
    )
}
    