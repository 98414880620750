import { Header } from "../components/Header"
import { Banner, Button, Description, Title, Hero, IllustrationImg, Transition, Wave } from "../utils/styles"
import { Footer } from "../components/Footer";


export const AccueilPage = () => 
{

    return (
        <div>
            <Header/>
            <Banner id="#accueil">
                <Hero>
                    <Title>Des projets ambitieux.</Title>
                    <Description>Application mobile, application web, extension chrome, bot, prestations..</Description>
                    <Button style={{zIndex: 100}} href="/portfolio">En savoir plus</Button>
                </Hero>
            </Banner>
            <Wave xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fill-opacity="1" d="M0,160L60,149.3C120,139,240,117,360,96C480,75,600,53,720,74.7C840,96,960,160,1080,160C1200,160,1320,96,1380,64L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></Wave>
            <Transition id="portfolio">                
                <h2 style={{color: "#000", maxWidth: "850px"}}>Cyanstone est un ensemble de projets axés sur la création d'application : web, logiciel et mobile simplifiant ou améliorant l'expérience utilisateur d'application existante.</h2>
                <IllustrationImg src="/public/developer.png" />
            </Transition>
            <Footer />
        </div>
    )
}