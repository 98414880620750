import { SetStateAction, useState } from "react";
import { Header } from "../../components/Header"
import { PortfolioTitle, PortfolioSection, PortfolioList, PortfolioListItem, PortfolioParagraph, PortfolioA, PortfolioImage, PortfolioImageDescription, PortfolioImageSection, PortfolioImageContainer } from "../../utils/styles"
import { FaUser } from 'react-icons/fa'
import { Footer } from "../../components/Footer";

export const AlamaPage = () => 
{

    return (
        <div style={{height: "100%"}}>
            <Header blanc/>
            <section style={{paddingTop: "120px", margin: "0px 20px 0px 40px"}}>
                <PortfolioSection>
                    <PortfolioTitle>Description</PortfolioTitle>
                    <PortfolioParagraph>Alama [Hosting] était un projet web sur lequel un ami et moi avions travaillé pendant plus de 2 ans. Le site proposa des services d'hébergement pour serveur de jeux vidéo (Minecraft, ARK, Garry's Mod..). À l'origine Alama devait uniquement héberger nos serveurs de jeux mais nous avions finalement ouvert nos services au public.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Difficultés</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Premier projet personnel</PortfolioListItem>
                        <PortfolioListItem>Aucune expérience dans Linux</PortfolioListItem>
                        <PortfolioListItem>Aucune expérience dans la programmation en général</PortfolioListItem>
                        <PortfolioListItem>Budget</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Compétences acquises</PortfolioTitle>
                    <PortfolioList>
                    <PortfolioListItem>Dépannage de serveur Ubuntu (Linux)</PortfolioListItem>
                    <PortfolioListItem>Gestion d'un service client</PortfolioListItem>
                    <PortfolioListItem>Travail en équipe</PortfolioListItem>
                    <PortfolioListItem>Premier regard sur Docker</PortfolioListItem>
                    <PortfolioListItem>Configuration d'un nom de domaine</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Etat du projet</PortfolioTitle>
                    <PortfolioParagraph>Alama allait devenir une entreprise, cependant suite à <PortfolioA target="_blank" href="https://fr.wikipedia.org/wiki/Incendie_du_centre_de_donn%C3%A9es_d%27OVHcloud_%C3%A0_Strasbourg">l'incendie d'OVH</PortfolioA> en mars 2021 nous avons dû stopper toute activité par manque de budget, les donnéees stockées sur nos serveurs fus brulés et nous avons dû rembourser l'intégralité de nos clients, menant le groupe à la bankrupt.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Captures d'écrans</PortfolioTitle>
                    <PortfolioImageContainer>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Page accueil</PortfolioImageDescription>
                            <PortfolioImage src="/public/alama/accueil.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Page boutique</PortfolioImageDescription>
                            <PortfolioImage src="/public/alama/shop.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Page des serveurs</PortfolioImageDescription>
                            <PortfolioImage src="/public/alama/serveurs.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Page des serveurs après ajout d'un chat</PortfolioImageDescription>
                            <PortfolioImage src="/public/alama/serveurs_v2.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Page des effets</PortfolioImageDescription>
                            <PortfolioImage src="/public/alama/effets.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Page d'un serveur</PortfolioImageDescription>
                            <PortfolioImage src="/public/alama/panel.png" />
                        </PortfolioImageSection>
                    </PortfolioImageContainer>
                </PortfolioSection>
            </section>
            <Footer />
        </div>
    )
}