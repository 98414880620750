import { Header } from "../components/Header"
import { Button, Container, Flex, Grid, GridItem, GridThumbnail, GridTitle, GridDescription } from "../utils/styles"
import { Footer } from "../components/Footer";

export const PortfolioPage = () => 
{

    return (
        <div style={{background: "#ffffff", width: "100%", height: "100%"}}>
            <Header blanc/>
            <Container style={{display: "flex", flexDirection: "column", paddingTop: "100px", minHeight: "500px"}}>
                <Grid>
                    <GridItem>
                        <GridThumbnail src="/public/portfolio/logo_streamapp.png" />
                        <Flex justifyContent="center" flexDirection="column" alignItems="center">
                            <GridTitle>StreamApp</GridTitle>
                            <GridDescription>Extension Chrome | 2022</GridDescription>
                            <Button style={{marginBottom: "20px"}} href="/portfolio/streamapp">En savoir plus</Button>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <GridThumbnail src="/public/portfolio/logo_drush.png" />
                        <Flex justifyContent="center" flexDirection="column" alignItems="center">
                            <GridTitle>DRush</GridTitle>
                            <GridDescription>Site Web | 2022</GridDescription>
                            <Button style={{marginBottom: "20px"}} href="/portfolio/drush">En savoir plus</Button>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <GridThumbnail src="/public/portfolio/logo_alama.png" />
                        <Flex justifyContent="center" flexDirection="column" alignItems="center">
                            <GridTitle>Alama Hosting</GridTitle>
                            <GridDescription>Site Web | 2019-2021</GridDescription>
                            <Button style={{marginBottom: "20px"}} href="/portfolio/alama">En savoir plus</Button>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <GridThumbnail src="/public/portfolio/logo_cometlux.png" />
                        <Flex justifyContent="center" flexDirection="column" alignItems="center">
                            <GridTitle>Cometlux</GridTitle>
                            <GridDescription>Projet BTS SNIR</GridDescription>
                            <Button style={{marginBottom: "20px"}} href="/portfolio/cometlux">En savoir plus</Button>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <GridThumbnail src="/public/portfolio/logo_seasim.png" />
                        <Flex justifyContent="center" flexDirection="column" alignItems="center">
                            <GridTitle>SeaSIM</GridTitle>
                            <GridDescription>Logiciel cursus BTS</GridDescription>
                            <Button style={{marginBottom: "20px"}} href="/portfolio/seasim">En savoir plus</Button>
                        </Flex>
                    </GridItem>
                    <GridItem>
                        <GridThumbnail src="/public/portfolio/logo_ecologique.png" />
                        <Flex justifyContent="center" flexDirection="column" alignItems="center">
                            <GridTitle>€co Logique</GridTitle>
                            <GridDescription>Application mobile</GridDescription>
                            <Button style={{marginBottom: "20px"}} href="/portfolio/ecologique">En savoir plus</Button>
                        </Flex>
                    </GridItem>
                </Grid>
            </Container>
            <Footer />
        </div>
    )
}