import { Header } from "../components/Header"
import { ContactContainer, ContactHero, ContactCase, ContactDesc, ContactTitle } from "../utils/styles"
import { FaEnvelope, FaLinkedin, FaPhoneAlt } from 'react-icons/fa'
import { Footer } from "../components/Footer";

export const ContactPage = () => 
{

    return (
        <div>
            <Header />
            <ContactHero>
                <ContactTitle>N'hésitez pas à prendre contact 👋</ContactTitle>
            </ContactHero>
            <ContactContainer>

                <ContactCase href="https://www.linkedin.com/in/vincent-brach-b2a161206/" target="_blank">
                    <FaLinkedin color="#0e76a8" size={40} />
                    <ContactDesc>Voir sur Linkedin</ContactDesc>
                </ContactCase>
                <ContactCase href="mailto: brachvincent.pro@gmail.com">
                    <FaEnvelope color="#0e76a8" size={40} />
                    <ContactDesc>brachvincent.pro@gmail.com</ContactDesc>
                </ContactCase>
                <ContactCase href="tel: +33613892837">
                    <FaPhoneAlt color="#0e76a8" size={40} />
                    <ContactDesc>+33 613892837</ContactDesc>
                </ContactCase>

            </ContactContainer>
            <Footer />
        </div>
    )
}