import { Header } from "../components/Header"
import { Banner, Button, Description, Title, Container, Hero, CompetenceContainer, CompetenceName, CompetenceItem,  CompetenceList, CompetenceListItem, PassionContainer, PassionItem, PassionName, LangueItem, SoftName, SoftContainer, SoftItem } from "../utils/styles"
import { FaBiking, FaBook, FaCode, FaDumbbell, FaMusic, FaRunning } from 'react-icons/fa'
import { Footer } from "../components/Footer";

export const AproposPage = () => {
    
    const softSkills = [
        'Autonomie',
        'Adaptation',
        'Esprit critique',
        'Résolution de problèmes',
        'Esprit d\'équipe',
        'Créativité',
        'Flexibilité',
    ];

    return (
        <div>
            <Header />
            <Banner id="#accueil" style={{ background: "#262626" }}>
                <Hero>
                    <Title>Vincent, développeur depuis 2019.</Title>
                    <Description>En parallèle de mon cursus en BUT Développement Web et Mobile, je passe mes journées à imaginer et développer des applications Web.</Description>
                    <Button href="/cv">Voir mon Curriculum vitae</Button>
                </Hero>
            </Banner>
            <Container style={{ display: "flex", flexDirection: "column" }}>
                <Title style={{ color: "#000", margin: "30px 0px" }}>Compétences</Title>
                <CompetenceContainer>
                    <CompetenceItem>
                        <CompetenceName>Node.JS</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Créer un serveur Web</CompetenceListItem>
                            <CompetenceListItem>Manipulation de données MySQL</CompetenceListItem>
                            <CompetenceListItem>Installation et utilisation de paquets NPM</CompetenceListItem>
                            <CompetenceListItem>Créer une API (express.js)</CompetenceListItem>
                            <CompetenceListItem>Websockets</CompetenceListItem>
                            <CompetenceListItem>Expérience approfondie dans le paquet Discord.JS</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>React.JS</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Développement d'un site</CompetenceListItem>
                            <CompetenceListItem>Création de hooks personnalisés</CompetenceListItem>
                            <CompetenceListItem>Intégration d'une API</CompetenceListItem>
                            <CompetenceListItem>Utilisation de Styled Components</CompetenceListItem>
                            <CompetenceListItem>Sécuriser les Routes</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>React Native</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Création d'application mobile</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>Nest.Js</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Développement d'API avec Controllers, Interfaces et Services</CompetenceListItem>
                            <CompetenceListItem>Utilisation de websockets</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
					<CompetenceItem>
                        <CompetenceName>Express.Js</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Développement d'API avec Controllers, Interfaces et Services</CompetenceListItem>
                            <CompetenceListItem>Utilisation de websockets</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>SQL</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Création d'une base de données</CompetenceListItem>
                            <CompetenceListItem>Héberger une base de données</CompetenceListItem>
                            <CompetenceListItem>Utilisation, manipulation de base de données relationnelles</CompetenceListItem>
                            <CompetenceListItem>Compréhension d'un ORM</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>HTML CSS JS</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Développement d'un site avec HTML</CompetenceListItem>
                            <CompetenceListItem>Stylage d'un site avec CSS</CompetenceListItem>
                            <CompetenceListItem>Animation JS et intégration de données (via EJS)</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>Linux (Ubuntu)</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Utilisation du système d'exploitation</CompetenceListItem>
                            <CompetenceListItem>Installation de package</CompetenceListItem>
                            <CompetenceListItem>Configuration NGINX</CompetenceListItem>
                            <CompetenceListItem>Dépannage et maintenance d'un serveur</CompetenceListItem>
                            <CompetenceListItem>Planification de tâches avec CRON</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>C# (Windows Form)</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Développement d'un logiciel</CompetenceListItem>
                            <CompetenceListItem>Intégration SQL</CompetenceListItem>
                            <CompetenceListItem>Utilisation et installation de NuGet</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>Figma</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Modélisation de maquettes</CompetenceListItem>
                            <CompetenceListItem>Modélisation de wireframe</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>PHP</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>REST API (Slim)</CompetenceListItem>
                            <CompetenceListItem>Eloquent</CompetenceListItem>
                            <CompetenceListItem>Composer</CompetenceListItem>
                            <CompetenceListItem>JWT Token</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>Chromium</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Création d'extension chrome</CompetenceListItem>
                            <CompetenceListItem>Manifest v2</CompetenceListItem>
                            <CompetenceListItem>Manifest v3</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
                    <CompetenceItem>
                        <CompetenceName>Vue.js</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Développement de site</CompetenceListItem>
                            <CompetenceListItem>Intégration d'API</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
					<CompetenceItem>
                        <CompetenceName>Git</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Connaissances des différentes commandes</CompetenceListItem>
                            <CompetenceListItem>Travailler à plusieurs sur le même projet</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
					<CompetenceItem>
                        <CompetenceName>Docker</CompetenceName>
                        <CompetenceList>
                            <CompetenceListItem>Création d'image</CompetenceListItem>
                            <CompetenceListItem>Utilisation et rédaction d'un docker compose</CompetenceListItem>
                        </CompetenceList>
                    </CompetenceItem>
					<CompetenceItem>
                        <CompetenceName>Et bien plus..</CompetenceName>
                    </CompetenceItem>
                </CompetenceContainer>

                <Container style={{ display: "flex", flexDirection: "column" }}>
                    <Title style={{ color: "#000" }}>Soft-skills</Title>
                    <SoftContainer style={{width: "100%"}}>
                        {softSkills.map((skill, index) => (
                            <SoftItem key={index}>
                                <SoftName>{skill}</SoftName>
                            </SoftItem>
                        ))}
                    </SoftContainer>
                </Container>
                
                <Container style={{ display: "flex", flexDirection: "column" }}>
                    <Title style={{ color: "#000" }}>Loisirs</Title>
                    <PassionContainer style={{width: "100%"}}>
                        <PassionItem><FaBiking size={50} /><PassionName>Vélo</PassionName></PassionItem>
                        <PassionItem><FaRunning size={50} /><PassionName>Athlétisme</PassionName></PassionItem>
                        <PassionItem><FaDumbbell size={50} /><PassionName>Musculation</PassionName></PassionItem>
                        <PassionItem><FaBook size={50} /><PassionName>Lecture</PassionName></PassionItem>
                        <PassionItem><FaCode size={50} /><PassionName>Programmation</PassionName></PassionItem>
                        <PassionItem><FaMusic size={50} /><PassionName>Piano</PassionName></PassionItem>
                    </PassionContainer> 
                </Container>

                <Container style={{ display: "flex", flexDirection: "column" }}>
                    <Title style={{ color: "#000" }}>Langues</Title>
                    <PassionContainer style={{width: "100%"}}>
                        <LangueItem><img width={100} src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg" /><PassionName>Français (Natale)</PassionName></LangueItem>
                        <LangueItem><img width={100} src="https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg" /><PassionName>Anglais (Avancé)</PassionName></LangueItem>
                        <LangueItem><img width={100} src="https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Italy.svg" /><PassionName>Italien (Apprentissage)</PassionName></LangueItem>
                        <LangueItem><img width={100} src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg" /><PassionName>Allemand (Notions)</PassionName></LangueItem>
                    </PassionContainer> 
                </Container>

                
            </Container>
            <Footer />
        </div>
    )
}
