import { Header } from "../../components/Header"
import { PortfolioTitle, PortfolioSection, PortfolioList, PortfolioListItem, PortfolioParagraph, PortfolioA, PortfolioImage, PortfolioImageDescription, PortfolioImageSection, PortfolioImageContainer } from "../../utils/styles"
import { Footer } from "../../components/Footer";


export const CometluxPage = () => 
{

    return (
        <div style={{height: "100%"}}>
            <Header blanc/>
            <section style={{paddingTop: "120px", margin: "0px 20px 0px 40px"}}>
                <PortfolioSection>
                    <PortfolioTitle>Description</PortfolioTitle>
                    <PortfolioParagraph>Le projet consiste à développer une solution de commande automatisée de l'éclairage de projecteur LEDs avec une prise de commande à distance, avec à disposition une LED manipulable par WIFI, une led manipulable par XBEE et des lampes HUE.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Problématique</PortfolioTitle>
                    <PortfolioParagraph>La règle d'or pour un commerce est de vendre du produit. Un bon éclairage rend attractif de  l'extérieur et met en valeur les produits à l'intérieur. Afin de réduire les coûts et d'augmenter les ventes, on utilise des équipements à LEDs. Le passage aux LEDs permet de baisser les charges et leur fiabilité éviter d'avoir des luminaires  éteints envoyant un mauvais message aux clients. </PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Equipe et répartition des tâches</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Mr. COLLIGNON Ethan, développement de la création de scénario sur le logiciel, manipulation des lampes HUE, visionnage des logs sur le logiciel</PortfolioListItem>
                        <PortfolioListItem>Mr. FREYERMUTH Regis, manipulation des projecteurs LED par XBEE</PortfolioListItem>
                        <PortfolioListItem>Mr. GLAD Alexy, modélisation de la base de données, émulation d'un prototype sur <PortfolioA target="_blank" href="https://www.domoticz.com/">Domoticz</PortfolioA>, développement d'un logiciel pour interpréter les changements d'état de lampe dans la base de données et d'appliquer ceux-ci, développement d'un logiciel pour exécuter les scénarios enregistrés. </PortfolioListItem>
                        <PortfolioListItem>Mr. BRACH Vincent (moi), <PortfolioA href="https://www.figma.com/file/uEQqIsXVaHpdsRyAbJ3O63/CometLux-Maquette-Logiciel?type=design&node-id=0%3A1&t=QPc2T6drDSZvjKkv-1" target="_blank">modélisation des différentes maquettes</PortfolioA>, interfaçage de l'application commune, développement de l'application Web, manipulation des projecteurs LED par WIFI.</PortfolioListItem>
                    </PortfolioList>                     
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Solutions apportées</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Application C# (Windows Form) pour chaque type d'éclairage</PortfolioListItem>
                        <PortfolioListItem>Application C# (Windows Form) commune pour contrôler tous les types d'éclairage</PortfolioListItem>
                        <PortfolioListItem>Site web accessible depuis tout appareil</PortfolioListItem>
                        <PortfolioListItem>Serveur pour la base de données</PortfolioListItem>
                    </PortfolioList>                
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Matériels</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Carte ESP32 (pour WIFI)</PortfolioListItem>
                        <PortfolioListItem>Carte XBEE</PortfolioListItem>
                        <PortfolioListItem>Lampes HUE</PortfolioListItem>
                        <PortfolioListItem>Maquette de centrale d'éclairage</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Compétences acquises</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Création d'un serveur web sur Arduino</PortfolioListItem>
                        <PortfolioListItem>Création d'un site web avec NodeJS (avec express.js)</PortfolioListItem>
                        <PortfolioListItem>Découverte et utilisation de EJS</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Code source des différentes solutions (Github)</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Site web : <PortfolioA target="_blank" href="https://github.com/BrachV/cometlux-web">cometlux-web</PortfolioA></PortfolioListItem>
                        <PortfolioListItem>Programme arduino : <PortfolioA target="_blank" href="https://github.com/BrachV/cometlux-esp32">cometlux-esp32</PortfolioA></PortfolioListItem>
                        <PortfolioListItem>Logiciel C# personnel  : <PortfolioA target="_blank" href="https://github.com/BrachV/cometlux-light-control-wifi">cometlux-light-control-wifi </PortfolioA></PortfolioListItem>
                        <PortfolioListItem>Logiciel C# commun  : <PortfolioA target="_blank" href="https://github.com/BrachV/cometlux-light-control-commun">cometlux-light-control-commun</PortfolioA> </PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>
                
                <PortfolioSection>
                    <PortfolioTitle>Etat du projet</PortfolioTitle>
                    <PortfolioParagraph>Le projet est achevé, cependant il reste à l'état de prototype car très peu sécurisé. Les aspects de sécurisation du développement ne font pas partie du programme du BTS SNIR.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Quelques captures d'écrans</PortfolioTitle>
                    <PortfolioImageContainer>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Accueil (Logiciel)</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/logiciel_accueil.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Planification (Logiciel)</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/logiciel_planif.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Ajouter une lampe</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/logiciel_ajout_lampe.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>UML - Cas d'utilisations</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/uml.png" />
                        </PortfolioImageSection>
                    </PortfolioImageContainer>
                </PortfolioSection>
                
                <PortfolioSection>
                    <PortfolioTitle>Quelques photos du projet</PortfolioTitle>
                    <PortfolioImageContainer>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Test unitaire ESP32</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/test_unitaire_ledbar.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Test unitaire XBEE</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/test_unitaire_xbee.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Test unitaire HUE</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/test_unitaire_hue.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>PC Serveur/Gestionnaire</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/pc_gestionnaire.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Matériel XBEE</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/materiel_xbee.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Résultat final de l'ESP32 sur la maquette d'éclairement</PortfolioImageDescription>
                            <PortfolioImage src="/public/cometlux/esp32.png" />
                        </PortfolioImageSection>
                    </PortfolioImageContainer>
                </PortfolioSection>et photos du projet
            </section>
            <Footer />
        </div>
    )
}