import { Header } from "../../components/Header"
import { PortfolioTitle, PortfolioSection, PortfolioList, PortfolioListItem, PortfolioParagraph, PortfolioSpan, PortfolioA, PortfolioImage, PortfolioImageDescription, PortfolioImageSection, Grid } from "../../utils/styles"
import { Footer } from "../../components/Footer";


export const StreamappPage = () => 
{

    return (
        <div style={{height: "100%"}}>
            <Header blanc/>
            <section style={{paddingTop: "120px", margin: "0px 20px 0px 40px"}}>
                <PortfolioSection>
                    <PortfolioTitle>Description</PortfolioTitle>
                    <PortfolioParagraph>StreamApp est une application Google Chrome développé pour améliorer l'expérience utilisateur du site <PortfolioA target="_blank" href="https://twitch.tv/">twitch.tv</PortfolioA></PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Problématique</PortfolioTitle>
                    <PortfolioParagraph>Twitch est un service de streaming vidéo en direct dans lequel des <PortfolioSpan>streamers</PortfolioSpan> propose leur contenu sur la plateforme, que des <PortfolioSpan>viewers</PortfolioSpan> (=spectateurs) peuvent visionner. Le seul moyen de savoir quels streamers est en direct, est de se rendre sur le site Twitch et de chercher dans une liste, souvent longue, pouvant décourager l'utilisateur.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Solutions apportées</PortfolioTitle>
                    <PortfolioParagraph>L'extension propose deux solutions, la première est active : l'utilisateur peut choisir de recevoir une notification dès lors qu'un streamer démarre un direct, la seconde est passive : l'utilisateur peut cliquer sur le pop-up en haut à droite de son navigateur pour vérifier si ses streamers favoris sont en ligne.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Difficultés</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Méconnaissance de l'API Google</PortfolioListItem>
                        <PortfolioListItem>Premier projet à être publié sur le web</PortfolioListItem>
                        <PortfolioListItem>Système de connexion par compte à Twitch par l'API (avec OpenID)</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Compétences acquises</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Création d'extension Chrome via l'API de Google Chrome</PortfolioListItem>
                        <PortfolioListItem>Découverte et utilisation de JQuery</PortfolioListItem>
                        <PortfolioListItem>Principe d'authentification avec OpenID</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Etat du projet</PortfolioTitle>
                    <PortfolioParagraph>L'application ne comporte pas encore de conditions générales d'utilisation et de politique de confidentialité, une fois ces éléments rédigés l'application pourra être publiée.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Capture d'écran du projet</PortfolioTitle>
                    <Grid>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Page de connexion</PortfolioImageDescription>
                            <PortfolioImage src="/public/streamapp/page_connexion.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Page principale</PortfolioImageDescription>
                            <PortfolioImage src="/public/streamapp/page_accueil.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Ajouter un streamer</PortfolioImageDescription>
                            <PortfolioImage src="/public/streamapp/page_ajout_streamer.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Importer ses streamers</PortfolioImageDescription>
                            <PortfolioImage src="/public/streamapp/page_import.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Clic droit sur un streamer</PortfolioImageDescription>
                            <PortfolioImage src="/public/streamapp/page_clic_droit.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Menu déroulant</PortfolioImageDescription>
                            <PortfolioImage src="/public/streamapp/menu_deroulant.png" />
                        </PortfolioImageSection>
                    </Grid>
                </PortfolioSection>
            </section>
            <Footer />
        </div>
    )
}