import { Routes, Route } from 'react-router-dom'
import { AccueilPage } from './pages/AccueilPage'
import { CvPage } from './pages/CvPage'
import { PortfolioPage } from './pages/Portfolio'
import { AproposPage } from './pages/AproposPage'
import { ContactPage } from './pages/ContactPage'
import { StreamappPage } from './pages/Portfolio/StreamappPage'
import { SeasimPage } from './pages/Portfolio/SeasimPage'
import { CometluxPage } from './pages/Portfolio/CometluxPage'
import { EcologiquePage } from './pages/Portfolio/EcologiquePage'
import { AlamaPage } from './pages/Portfolio/AlamaPage'
import { DrushPage } from './pages/Portfolio/DrushPage'

function App() {

  return (
	<Routes>
		<Route path="/" element={<AccueilPage />} />
		<Route path="/accueil" element={<AccueilPage />} />
		<Route path="/portfolio" element={<PortfolioPage />} />
		<Route path="/a-propos" element={<AproposPage />} />
		<Route path="/contact" element={<ContactPage />} />
		<Route path="/cv" element={<CvPage />} />

		<Route path="/portfolio/alama" element={<AlamaPage />} />
		<Route path="/portfolio/drush" element={<DrushPage />} />
		<Route path="/portfolio/ecologique" element={<EcologiquePage />} />
		<Route path="/portfolio/streamapp" element={<StreamappPage />} />
		<Route path="/portfolio/cometlux" element={<CometluxPage />} />
		<Route path="/portfolio/seasim" element={<SeasimPage />} />
	</Routes>
  )
}

export default App
