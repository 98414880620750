import { Header } from "../../components/Header"
import { PortfolioTitle, PortfolioSection, PortfolioList, PortfolioListItem, PortfolioParagraph, PortfolioImage, PortfolioImageDescription, PortfolioImageSection, PortfolioImageContainer } from "../../utils/styles"
import { Footer } from "../../components/Footer";


export const SeasimPage = () => 
{

    return (
        <div style={{height: "100%"}}>
            <Header blanc/>
            <section style={{paddingTop: "120px", margin: "0px 20px 0px 40px"}}>
                <PortfolioSection>
                    <PortfolioTitle>Description</PortfolioTitle>
                    <PortfolioParagraph>Seasim est un logiciel de simulation de séance pour professeur. J'ai pu travaillé sur ce projet avec l'aide de mon camarade Mr. COLLIGNON Ethan.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Besoin répondu</PortfolioTitle>
                    <PortfolioParagraph>Récueillir les stratégies d'enseignements des enseignants en formation.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Solutions apportées</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Simulation de séance</PortfolioListItem>
                        <PortfolioListItem>Evaluation de séance</PortfolioListItem>
                        <PortfolioListItem>Lecteur MP3 intégré</PortfolioListItem>
                        <PortfolioListItem>Paint intégré</PortfolioListItem>
                    </PortfolioList>                
                </PortfolioSection>
                <PortfolioSection>
                    <PortfolioTitle>Difficulté</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Connaissance basique de Windows Form .NET</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Compétences acquises</PortfolioTitle>
                    <PortfolioList>
                        <PortfolioListItem>Manipulation de fichiers Audio avec NAudio</PortfolioListItem>
                        <PortfolioListItem>Manipulation graphique pour recréer un Paint</PortfolioListItem>
                        <PortfolioListItem>Meilleure compréhension du langage C# et de Windows Form .NET</PortfolioListItem>
                    </PortfolioList>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Etat du projet</PortfolioTitle>
                    <PortfolioParagraph>Le logiciel Seasim est terminé ; cependant "Seasim" est une propriété intellectuel de l'université de Lorraine, nous avons pu travailler dessus avec l'accord de notre professeur mais il nous est interdit de redistribuer le logiciel développé.</PortfolioParagraph>
                </PortfolioSection>

                <PortfolioSection>
                    <PortfolioTitle>Capture d'écran du projet</PortfolioTitle>
                    <PortfolioImageContainer>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Page d'accueil</PortfolioImageDescription>
                            <PortfolioImage src="/public/seasim/accueil.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Création d'une séance</PortfolioImageDescription>
                            <PortfolioImage src="/public/seasim/creation_seance.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Renseigner les étapes</PortfolioImageDescription>
                            <PortfolioImage src="/public/seasim/renseignement_etapes.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Enregistrement vocal</PortfolioImageDescription>
                            <PortfolioImage src="/public/seasim/enregistrement.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Dessiner le contenu d'une étape</PortfolioImageDescription>
                            <PortfolioImage src="/public/seasim/paint.png" />
                        </PortfolioImageSection>
                        <PortfolioImageSection>
                            <PortfolioImageDescription>Evaluer une étape</PortfolioImageDescription>
                            <PortfolioImage src="/public/seasim/evaluation.png" />
                        </PortfolioImageSection>
                    </PortfolioImageContainer>
                </PortfolioSection>
            </section>
            <Footer />
        </div>
    )
}