import styled, { css } from "styled-components";



type FlexProps = Partial<{
    alignItems: string;
    justifyContent: string;
    flexDirection: string;
}>
export const Flex = styled.div<FlexProps>`
    display: flex;
    align-items: ${({alignItems}) => alignItems};
    justify-content: ${({justifyContent}) => justifyContent};
    flex-direction: ${({flexDirection}) => flexDirection};
    
`

export const Banner = styled.section`
    //position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(/public/banner.png) ;
    background-size: cover;
`
type StyleProps = Partial<{
    theme?: "clair" | "sombre"
}>
export const Title = styled.h2<StyleProps>`
    font-size: 4.5em;
    word-break: break-word;
    text-align: center;
    ${({theme}) => theme === 'clair' && css`
        color: white;
    ` };
    ${({theme}) => theme === 'sombre' && css`
        color: black;
    ` };


    @media (max-width: 768px) {
        font-size: 3.5em;
    }
`
export const Description = styled.p`
    font-size: 1.3em;
    color: white;
`
export const Button = styled.a`
    font-size: 1em;
    color: #fff;
    background: #ff0157;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;
    &:hover {
        border-color: white;
        letter-spacing: 2px;
    };
`
export const Transition = styled.section`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-size: cover;
    text-align: center;    

    @media (min-width: 768px) {
        margin-top: -150px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
`


export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    grid-column: auto;

    @media (max-width: 1250px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 810px) {
        grid-template-columns: 1fr;
    }
    
`

export const GridItem = styled.div`
    overflow: hidden;
    width: 350px;
    background: #fff;
    border-radius: 20px;
    margin: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 5px;

    @media (max-width: 810px) {
        width: 300px;
    }
`

export const GridThumbnail = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 32px;
    background-color: inherit;
    transition: transform .2s; /* Animation */
    width: auto;
    height: 200px;
    margin: 0 auto;
    overflow: hidden;
    &:hover {
        transform: scale(1.08); 
    }
`

export const GridTitle = styled.h2`
    margin: 10px 0 0 0;
    color: #000;
    font-weight: 700;
    background: #fff;
    z-index: 2 !important;
`

export const GridDescription = styled.i`
    color: #000;
    margin: 0;
    font-weight: 100;
`

export const Wave = styled.svg`
    z-index: 5;
    margin-top: -220px;
`

// export const CompetenceContainer = styled.ul`
//     width: 100%;
//     border-radius: 5px;
//     margin: 0;
// `
export const CompetenceContainer = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-column: auto;
    justify-content: center;
    align-items: start;
    padding: 0px 10px;
    @media (max-width: 950px) {
        font-size: 20px;
    }
    @media (max-width: 680px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
export const CompetenceName = styled.span`
    color: #fefefe;
    text-transform: uppercase;
    color: #2a7da2;
    font-size: 36px;
    font-weight: 600;

    @media (max-width: 950px) {
        font-size: 30px;
    }
`
export const CompetenceItem = styled.div`
    display: flex;
    margin: 20px 50px;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    flex: 50%;

    @media (max-width: 950px) {
        margin: 20px 0px;
    }
`

export const CompetenceList = styled.ul`
    list-style: inside;
    @media (max-width: 1300px) {
        padding-left: 10px;
    }
`
export const CompetenceListItem = styled.li`
    font-size: 28px;
    color: #000;

    @media (max-width: 1300px) {
        font-size: 20px;
    }
`



export const PassionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    /* grid-column: auto; */
    align-items: center;
    justify-content: center;

    @media (min-width: 950px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1650px) {
        grid-template-columns: repeat(3, 1fr);
    }
`

export const PassionItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    // padding: 0px 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 20px 100px;
    border-radius: 20px;
    height: 100px;
    background-color: #f9f8f8;
    font-size: 28px;

    @media (max-width: 650px) {
        width: 340px;
    }
`

export const PassionName = styled.p`
    padding-left: 20px;
    font-weight: 700;
`

export const LangueItem = styled(PassionItem)`
    justify-content: start;
    padding-left: 30px;
`
export const SoftContainer = styled(PassionContainer)`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-column: auto;
    align-items: center;
    justify-content: center;

    @media (min-width: 650px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 950px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1650px) {
        grid-template-columns: repeat(4, 1fr);
    }
`
export const SoftName = styled(PassionName)`
    padding: 0;
    text-align: center;
`
export const SoftItem = styled(PassionItem)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    height: 100px;
    margin: 20px 50px;
    background-color: #f9f8f8;
    font-size: 28px;
    width: auto;

    @media (max-width: 950px) {
        margin: 15px;
    }
`


export const Container = styled.section`
    position: relative;
    overflow: hidden;
    width: 100%;
    /* min-height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-size: cover; */
`
export const Hero = styled.div`
    max-width: 900px;
    text-align: center;
`
export const IllustrationImg = styled.img`
    width: 300px;
`
export const StatContainer = styled.section`
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-grow: 1;
    background-size: cover;
`

export const StatItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #999;
    border-radius: 10px;
    border: 1px solid #000;
    margin: 10px;
    padding: 10px;
    flex-grow: 1;
`

export const StatIndice = styled.p`
    margin: 10px 0 0 0;
    text-align: center;
`

export const StatValeur = styled.h3`
    margin: 0px;
    text-align: center;

`
export const Nav = styled(Flex)`

    align-items: center;

    @media (max-width: 680px) {
        font-size: 14px;
    }
`
export const HeaderContainer = styled(Flex)`
    @media (max-width: 680px) {
        flex-direction: column;
    }
`

export const NavItem = styled.a`
    z-index: 3;
    position: relative;
    padding: 0px 20px;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color: #ffffff;
    &:hover {
        color: #ff0157;
    };
    transition: .2s;

    @media (max-width: 806px) {
        padding: 0px 10px;
    }

    @media (max-width: 680px) {
        font-size: 15px;
        padding: 0px 5px;
        width: 70px;
    }
`

export const Logo = styled.a`
    margin-top: 5px;
    color: #ffffff;
    user-select: none;
    font-size: 36px;
    font-weight: 700;
    text-decoration: none;

    @media (min-width: 680px) {
        margin-left: 20px;
    }
`
export const Dot = styled.span`
    color:#ff0157; 
    font-size: 36px;
`













export const ContactHero = styled(Container)`
    position: relative;
    display: flex;
    height: 350px;
    text-align: center;
    background: #252525;
    @media (max-width: 768px) {
        padding-top: 90px;
        height: 200px;
    }
`
export const ContactTitle = styled.h1`
    font-size: 64px;
    @media (max-width: 768px) {
        font-size: 32px;
    }

`
export const ContactContainer = styled.section`
    position: relative;
    display: block;

    justify-content: center;
    height: 300px;
    flex-direction: column;

    @media (min-width: 768px) {
        margin-bottom: 200px;
    }
    
`

export const ContactCase = styled.a`
    display: flex;
    background-color: #f0f0f0;
    align-items: center;
    text-align: center;
    /* border: 1px solid #000; */
    height: auto 50px;
    margin: 30px 50px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #000;
    cursor: pointer;
    text-decoration: none;

`

export const ContactDesc = styled.p`

    color: #020202;
    font-size: 32px;
    font-weight: 500;
    padding-left: 10px;
    margin: 0px;
    word-break: break-all;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`

export const ContactInput = styled.input`
    width: 55%;
`





export const PortfolioSection = styled.section`
`

export const PortfolioTitle = styled.h2`
    color: #2a7da2;
    font-size: 32px;
    margin: 25px 0px 10px 0px;

`

export const PortfolioList = styled.ul`

`

export const PortfolioListItem = styled.li`
    color: #000;
    font-size: 24px;
    font-weight: 600;
`
export const PortfolioParagraph = styled.p`
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0px;
`
export const PortfolioSpan = styled.span`
    color: #568BE2;
`
export const PortfolioA = styled.a`
    color: #568BE2;
`
export const PortfolioImageSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    flex-direction: column;
`
export const PortfolioImageDescription = styled.p`
`
export const PortfolioImage = styled.img`
    object-fit: contain;
    max-width: 850px;
`
export const PortfolioImageContainer = styled.div`
    display: flex;
    flex-direction: wrap;
    flex-wrap: wrap;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-around;
`