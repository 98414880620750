import { FaMapMarkerAlt, FaPhoneAlt, FaBirthdayCake, FaEnvelope, FaGlobe, FaLinkedin } from 'react-icons/fa'

export const CvPage = () => 
{

    return (
        <div id="cv">
            <div className="container">
    <div className="left_Side">
      <div className="profileText">
        <div className="imgBx">
          <img src="/public/cv/photo.jpg" alt="Photo" />
        </div>
        <h2>BRACH Vincent<br /><span>Etudiant BUT DWM<br />3EME ANNEE </span></h2>
      </div>
      <div className="contactInfo">
        <h3 className="title">Coordonnées</h3>
        <ul>
          <li>
            <span className="icon"><FaMapMarkerAlt />
            </span>
            <span className="text">Nancy</span>
          </li>
          <li>
            <span className="icon"><FaPhoneAlt />
            </span>
            <span className="text">+33 613892837</span>
          </li>
          <li>
            <span className="icon"><FaBirthdayCake /></span>
            <span className="text">22/12/2003, 19 ans</span>
          </li>
          <li>
            <span className="icon"><FaEnvelope /></span>
            <span className="text">brachvincent.pro@<br />gmail.com</span>
          </li>
          <li>
            <span className="icon"><FaGlobe /></span>
            <span className="text">cyanstone.fr</span>
          </li>
          <li>
            <span className="icon"><FaLinkedin /></span>
            <span className="text">linkedin.com/in<br />/vincent-brach-b2a161206</span>
          </li>


        </ul>
      </div>
      <div className="contactInfo education">
        <h3 className="title">Parcours Educatif</h3>
        <ul>
        <li>
            <h5>2023 - 2024</h5>
            <h4>BUT DMW</h4>
            <h4>UL - IUT Charlemagne</h4>
          </li>
          <li>
            <h5>2021 - 2023</h5>
            <h4>BTS SNIR</h4>
            <h4>Lycée POL La Briquerie</h4>
          </li>
          <li>
            <h5>2019 - 2021</h5>
            <h4>BAC STI2D</h4>
            <h4>Lycée POL La Briquerie</h4>
          </li>
        </ul>
      </div>

      <div className="contactInfo language">
        <h3 className="title">Langues</h3>
        <ul>
          <li>
            <span className="text">Français</span>
            <span className="percent">
              <div style={{width: "98%"}}></div>            
            </span>
          </li>
          <li>
            <span className="text">Anglais</span>
            <span className="percent">
              <div style={{width: "80%"}}></div>            
            </span>
          </li>
          {/* <li>
            <span className="text">Allemand</span>
            <span className="percent">
              <div style={{width: "25%"}}></div>
            </span>
          </li>
          <li>
            <span className="text">Italien</span>
            <span className="percent">
              <div style={{width: "15%"}}></div>
            </span>
          </li> */}
        </ul>
      </div>
    </div>

    <div className="right_Side">
      <div className="about">
        <h2 className="title2">Profil</h2>
        <p>Fort d'une expérience en tant que développeur d'applications web depuis 2019 et actuellement étudiant en classe de BUT Développement Web et Mobile, je suis à la recherche d'une opportunité de stage en développement web, avec une disponibilité à partir de mi-avril. Mon parcours professionnel et mes compétences techniques font de moi un candidat enthousiaste et motivé pour contribuer activement à votre équipe et approfondir mes connaissances dans le domaine du développement web.</p>
      </div>
      <div className="about">
        <h2 className="title2">Experiences</h2>

        <div className="box">
          <div className="year_company">
            <h5>01/2023 - 06/2023</h5>
            <h5>Micro Entreprise</h5>
          </div>
          <div className="text">
            <h4>Développement d'une application mobile React Native</h4>
            <p>J'ai été approché par mes camarades du BTS Électrotechnique de mon lycée pour intervenir en tant que prestataire dans la création d'une application mobile permettant de contrôler une prise connectée.</p>
          </div>
        </div>

        <div className="box">
          <div className="year_company">
            <h5>05/2022 - 06/2022</h5>
            <h5>Streamrunners</h5>
          </div>
          <div className="text">
            <h4>Développement d'une application Javascript</h4>
            <p>Dans le cadre de mon stage de 6 semaines, nécessaires au BTS, il m'a été demandé de développer un automate pour la société sur la plateforme de communication Discord.</p>
          </div>
        </div>
        
        <div className="box">
          <div className="year_company">
            <h5>2019 - 2021</h5>
            <h5>Alama Hosting</h5>
          </div>
          <div className="text">
            <h4>Service de la comptabilité et des achats</h4>
            <p>Alama Hosting est un projet personnel démarré fin 2019 avec un ami, j'ai été affecté principalement à la recherche de nouveaux clients et à la gestion de la trésorerie du projet.</p>
          </div>
        </div>
        
      </div>

      <div className="about skills">
        <h2 className="title2">Aptitudes professionnelles</h2>


        <div className="box">
          <h4>Node JS</h4>
          <div className="percent">
          <div style={{width: "90%"}}></div>
          </div>
        </div>

        <div className="box">
          <h4>C & C++ & C#</h4>
          <div className="percent">
          <div style={{width: "75%"}}></div>
          </div>
        </div>

        <div className="box">
          <h4>MySQL</h4>
          <div className="percent">
          <div style={{width: "80%"}}></div>
          </div>
        </div>

        <div className="box">
          <h4>HTML & CSS & JS</h4>
          <div className="percent">
          <div style={{width: "85%"}}></div>
          </div>
        </div>

        <div className="box">
          <h4>React.Js</h4>
          <div className="percent">
          <div style={{width: "90%"}}></div>
          </div>
        </div>
        <div className="box">
          <h4>Nest.Js</h4>
          <div className="percent">
          <div style={{width: "80%"}}></div>
          </div>
        </div>
        <div className="box">
          <h4>Voir plus sur cyanstone.fr</h4>
        </div>
      </div>

      <div className="about interest">
        <h2 className="title2">Loisirs</h2>
        <ul>
          <li>Programmation</li>
          <li>Lecture (roman)</li>
          <li>VTT (forêt)</li>
          <li>Athlétisme</li>
        </ul>
      </div>
    </div>
  </div>
</div>
    )
}